<template>
  <div class="row">
    <div class="col-4">
      <label>Organization Type</label>
      <select v-model="selectedOrganizationType" :id="selectedOrganizationType" @change="changeOrganizationView">
        <option :value="-1">select class="form-select" an Organization Type</option>
        <option :value="1">Nebraska Educational Service Unit (ESU)</option>
        <option :value="2">Nebraska District</option>
        <option :value="3">Nebraska School</option>
        <option :value="4">Outstate Organization</option>
      </select>
      <div style="text-align: left;">
        <label>Filter By:</label>
        <input class="form-control" type="search" v-model="searchParam"
               :placeholder="searchHintText" aria-label="Search Query"/>
      </div>
    </div>
  </div>
  <div>
    <organization-table :institution-data="organizationList" :search-param="searchParam"
                        :field-headers="organizationHeaders" :institution-type="organizationType"/>
  </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import OrganizationTable from "@/views/SuperUserViews/EducationalOrganizationManagement/OrganizationTable";

export default {
  name: "OrganizationTableList",
  components: {OrganizationTable},
  setup() {
    const selectedOrganizationType = ref(-1);
    const organizationList = ref([]);
    const searchParam = ref("");
    const organizationHeaders = ref({});
    const organizationType = ref("");
    const searchHintText = ref("");

    onBeforeMount(() => {

      if (localStorage.getItem('storedOrganizationHeaders')) {
        organizationHeaders.value = JSON.parse(localStorage.getItem('storedOrganizationHeaders'));
      }

      if (localStorage.getItem('storedOrganizationType')) {
        organizationType.value = localStorage.getItem('storedOrganizationType');
      }

      if (localStorage.getItem('storedOrganizationSearch')) {
        selectedOrganizationType.value = parseInt(localStorage.getItem('storedOrganizationSearch'));
        changeOrganizationView();
      }

      if (localStorage.getItem('institutionSearchQuery')) {
        searchParam.value = localStorage.getItem('institutionSearchQuery');
      }

    })

    function changeOrganizationView() {
      switch (parseInt(selectedOrganizationType.value)) {

        case 1:
          getEsus();
          setEsuHeaders();
          organizationType.value = "ESU";
          searchHintText.value = "ESU Name, Address, City";
          break;
        case 2:
          getDistricts();
          setDistrictHeaders();
          searchHintText.value = "District Name, ESU, Address, City";
          organizationType.value = "District";
          break;
        case 3:
          setSchoolHeaders();
          getSchools();
          searchHintText.value = "School Name, District, ESU, Address, City";
          organizationType.value = "School";
          break;
        case 4:
          setOutstateOrganizationHeaders()
          getOutstateOrganizations();
          searchHintText.value = "Name, Address, City, State, Country";
          organizationType.value = "Outstate Organization";
          break;
        default:
          clearOldOrganizations();
          clearOrgHeaders();
          organizationType.value = "";
          break;
      }
      searchParam.value = "";
      localStorage.setItem('storedOrganizationSearch', selectedOrganizationType.value.toString());
      localStorage.setItem('storedOrganizationHeaders', JSON.stringify(organizationHeaders.value));
      localStorage.setItem('storedOrganizationType', organizationType.value);

    }


    async function getEsus() {

      let get_uri = API_URL + "/esu/displayAll?_isDisabled=" + btoa('false');
      await axios.get(get_uri)
          .then((result) => {
            result.data.sort((a, b) => a.esuName > b.esuName ? 1 : -1);
            let institution_temp = [];
            for (let i = 0; i < result.data.length; i++) {
              let institution = {
                name: result.data[i]['esuName'],
                address: result.data[i]['primaryEsuAddress'],
                phoneNumber: result.data[i]['esuPhoneNumber'],
                city: result.data[i]['esuCity'],
                cdn: result.data[i]['esuCdn'],
                idOrganization: result.data[i]['idEsu']
              }
              institution_temp.push(institution);
            }
            organizationList.value = institution_temp;
          })

    }

    function setEsuHeaders() {
      organizationHeaders.value = {
        name: "ESU Name",
        address: "Primary Address",
        phoneNumber: "Primary Phone Number",
        city: "City",
        cdn: "CDN",
      }
    }

    async function getDistricts() {
      let get_uri = API_URL + "/districts/allForDisplay";
      await axios.get(get_uri)
          .then((result) => {
            result.data.sort((a, b) => a.districtName > b.districtName ? 1 : -1);
            localStorage.setItem('storedOrganizationHeaders', JSON.stringify(organizationHeaders.value));
            let institution_temp = [];
            for (let i = 0; i < result.data.length; i++) {
              let institution = {
                name: result.data[i]['districtName'],
                address: result.data[i]['primaryDistrictAddress'],
                phoneNumber: result.data[i]['districtPhoneNumber'],
                city: result.data[i]['districtCity'],
                cdn: result.data[i]['districtCdn'],
                idOrganization: result.data[i]['idDistrict'],
                esu: result.data[i]['districtEsu'],
                zipCode: result.data[i]['districtZipCode']
              }
              institution_temp.push(institution);
            }
            organizationList.value = institution_temp;
          })
    }

    function setDistrictHeaders() {
      organizationHeaders.value = {
        name: "District Name",
        esu: "ESU",
        address: "Primary Address",
        phoneNumber: "Primary Phone Number",
        city: "City",
        cdn: "CDN",
        zipCode: "Zip"
      }
    }

    async function getSchools() {
      setSchoolHeaders();
      let get_uri = API_URL + "/school/allForDisplay";
      await axios.get(get_uri)
          .then((result) => {
            result.data.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
            let institution_temp = [];
            for (let i = 0; i < result.data.length; i++) {
              let institution = {
                name: result.data[i]['schoolName'],
                address: result.data[i]['primarySchoolAddress'],
                phoneNumber: result.data[i]['schoolPhoneNumber'],
                city: result.data[i]['schoolCity'],
                cdn: result.data[i]['schoolCdn'],
                idOrganization: result.data[i]['idSchool'],
                esu: result.data[i]['schoolEsu'],
                zipCode: result.data[i]['schoolZipCode'],
                district: result.data[i]['schoolDistrict']
              }
              institution_temp.push(institution);
            }
            organizationList.value = institution_temp;
          })
    }

    function setSchoolHeaders() {
      organizationHeaders.value = {
        name: "School Name",
        district: "District",
        esu: "ESU",
        address: "School Address",
        phoneNumber: "Phone Number",
        city: "City",
        cdn: "CDN",
        zipCode: "ZIP",
      }
    }

    async function getOutstateOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/all";
      await axios.get(get_uri)
          .then((result) => {
            let institution_temp = [];
            for (let i = 0; i < result.data.length; i++) {
              let institution = {
                name: result.data[i]['organizationName'],
                contactName: (result.data[i]['contactName'] !== null && result.data[i]['contactName'] !== undefined) ? result.data[i]['contactName'] : "",
                contactEmail: (result.data[i]['contactEmail'] !== null && result.data[i]['contactEmail'] !== undefined) ? result.data[i]['contactEmail'] : "",
                phoneNumber: (result.data[i]['phoneNumber'] !== null && result.data[i]['phoneNumber'] !== undefined) ? result.data[i]['phoneNumber'] : "",
                address: (result.data[i]['address'] !== null && result.data[i]['address'] !== undefined) ? result.data[i]['address'] : "",
                city: (result.data[i]['city'] !== null && result.data[i]['city'] !== undefined) ? result.data[i]['city'] : "",
                idOrganization: (result.data[i]['idOrganization'] !== null && result.data[i]['idOrganization'] !== undefined) ? result.data[i]['idOrganization'] : "",
                postalCode: (result.data[i]['postalCode'] !== null && result.data[i]['postalCode'] !== undefined) ? result.data[i]['postalCode'] : "",
                country: (result.data[i]['countryName'] !== null && result.data[i]['countryName'] !== undefined) ? result.data[i]['countryName'] : "",
                state: (result.data[i]['stateName'] !== null && result.data[i]['stateName'] !== undefined) ? result.data[i]['stateName'] : ''
              }
              institution_temp.push(institution);
            }
            organizationList.value = institution_temp;
          })
    }

    function setOutstateOrganizationHeaders() {
      organizationHeaders.value = {
        name: "Institution Name",
        contactName: "Contact Name",
        contactEmail: "Contact Email",
        phoneNumber: "Phone Number",
        address: "Institution Address",
        city: "City",
        state: "State",
        country: "Country",
        postalCode: "Postal Code (ZIP CODE)"
      }
    }


    function clearOldOrganizations() {
      while (organizationList.value.length > 0) {
        organizationList.value.pop();
      }
    }

    function clearOrgHeaders() {
      for (const key in organizationHeaders.value) {
        delete organizationHeaders.value[key];
      }
    }

    return {
      selectedOrganizationType,
      organizationList,
      searchParam,
      organizationHeaders,
      organizationType,
      searchHintText,
      changeOrganizationView

    }
  }
}
</script>

<style scoped>

</style>