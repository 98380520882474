<template>
  <div class="container-lg mt-2">
    <h4>{{ institutionType }}</h4>

    <div v-if="!isLoading">
      <table id="institutionTable" class="table table-bordered table-striped">
        <thead>
        <tr>

          <th v-for="(value, name, index) in fieldHeaders" :key="index" @click="sortTable(name)">{{ value }}
            <i :class="name === prevSortCol && storedReverse ?  'bi bi-sort-up' : 'bi bi-sort-down'"></i></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in filteredList" :key="item">
          <td v-for="(value, name, index) in fieldHeaders" :key="index">
            <p v-if="name === 'name'">
              <router-link v-if="institutionType === 'District'"
                           :to="{name: 'ViewDistrict', params: {idDistrict: item['idOrganization']}}">{{ item[name] }}
              </router-link>
              <!--&lt;!&ndash;            <h7 v-else>{{item[name]}}</h7>&ndash;&gt;-->
              <router-link v-else-if="institutionType === 'ESU'"
                           :to="{name: 'ViewEsu', params: {idEsu: item['idOrganization']}}">{{ item[name] }}
              </router-link>
              <router-link v-else-if="institutionType === 'School'"
                           :to="{name: 'ViewSchool', params: {idSchool: item['idOrganization']}}">{{ item[name] }}
              </router-link>
              <router-link v-else-if="institutionType === 'Outstate Organization'"
                           :to="{name: 'ViewOutstate', params: {idOrganization: item['idOrganization']}}">
                {{ item[name] }}
              </router-link>
              <span v-else-if="institutionType === 'Existing Organization'">
                              <button class="button btn-primary" type="submit"
                                      @click="selectOrganization(item['name'])">{{ item[name] }}</button>

              </span>
            </p>

            <p v-else>{{ item[name] }}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <label v-if="showCount">Found: {{ foundCount }}
      {{ institutionType }}{{ (foundCount > 1 || foundCount === 0) ? 's' : '' }}</label>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import {sortBy} from "lodash/collection";
import {onBeforeRouteLeave} from "vue-router";

export default {
  name: "OrganizationTable",
  props: {
    fieldHeaders: Object,
    institutionData: Array,
    searchParam: String,
    institutionType: String
  },
  setup(props, context) {
    let sort = ref(false);
    let prevSortCol = ref("");
    let isLoading = ref(true);
    let searchQuery = ref("");
    let updatedList = ref([]);
    let searchHintText = ref("Name");
    let storedReverse = ref(false);

    const showCount = computed(() => {
      return props.institutionType !== "" && props.institutionType !== null && props.institutionType !== undefined;
    })

    onBeforeMount(() => {
      // if (localStorage.getItem('institutionSearchResults')) {
      //   updatedList.value = JSON.parse(localStorage.getItem('institutionSearchResults'));
      //   isLoading.value = false;
      // }

      if (localStorage.getItem('institutionSearchQuery')) {
        searchQuery.value = localStorage.getItem('institutionSearchQuery')
      }


    })

    onBeforeRouteLeave(() => {
      let sort_reversed_temp = storedReverse.value ? 'true' : 'false';
      localStorage.setItem('orgSortReversed', sort_reversed_temp);
    })

    watch(() => props.institutionData, (first, second) => {
      updatedList.value = props.institutionData;

      if (updatedList.value.length > 0) {
        isLoading.value = false;
      }
      sortOnReroute(localStorage.getItem('orgSortCol'), (localStorage.getItem('orgSortReversed') === 'true'))
      localStorage.setItem('institutionSearchResults', JSON.stringify(updatedList.value));

    })

    watch(() => props.searchParam, (first, second) => {
      searchQuery.value = props.searchParam;
    })

    const foundCount = computed(() => {
      return filteredList.value.length;
    })


    const sortTable = (col) => {
      sort.value = true;

      if (col !== prevSortCol.value) {
        storedReverse.value = false;
        updatedList.value = sortBy(props.institutionData, col);
        localStorage.setItem('orgSortReversed', 'false');
      } else {
        storedReverse.value = !storedReverse.value;
        updatedList.value = updatedList.value.reverse();
      }
      prevSortCol.value = col;
      localStorage.setItem('orgSortCol', prevSortCol.value);
      localStorage.setItem('institutionSearchResults', JSON.stringify(updatedList.value));
    }

    const sortOnReroute = (col, reversed) => {
      updatedList.value = sortBy(props.institutionData, col);
      if (reversed) {
        updatedList.value = updatedList.value.reverse();
      }
      prevSortCol.value = col;
      storedReverse.value = reversed;
      localStorage.removeItem('orgSortReversed');
      localStorage.setItem('orgSortCol', prevSortCol.value);
      localStorage.setItem('institutionSearchResults', JSON.stringify(updatedList.value));
    }


    const filteredList = computed(() => {
      let ret_val = updatedList.value.filter((institution) => {
        let esu = "";
        let district = "";
        let state = "";
        let country = "";
        let address = institution.address.toLowerCase();

        // eslint-disable-next-line no-prototype-builtins
        if (props.fieldHeaders.hasOwnProperty('esu')) {
          searchHintText.value += ", ESU";
          esu = institution.esu.toLowerCase();
        }

        // eslint-disable-next-line no-prototype-builtins
        if (props.fieldHeaders.hasOwnProperty('district')) {
          searchHintText.value += ", District";
          district = institution.district.toLowerCase();
        }

        // eslint-disable-next-line no-prototype-builtins
        if (props.fieldHeaders.hasOwnProperty('state')) {
          searchHintText.value += ", State";
          state = institution.state.toLowerCase();
        }

        // eslint-disable-next-line no-prototype-builtins
        if (props.fieldHeaders.hasOwnProperty('country')) {
          searchHintText.value += ", and Country";
          country = institution.country.toLowerCase();
        }

        let name = institution.name.toLowerCase();
        let city = institution.city.toLowerCase();
        return name.includes(searchQuery.value.toLowerCase()) || city.includes(searchQuery.value.toLowerCase()) || esu.includes(searchQuery.value.toLowerCase())
            || district.includes(searchQuery.value.toLowerCase()) || country.includes(searchQuery.value.toLowerCase()) || state.includes(searchQuery.value.toLowerCase())
            || address.includes(searchQuery.value.toLowerCase());
      })
      localStorage.setItem('institutionSearchQuery', searchQuery.value);
      return ret_val;
    })

    function selectOrganization(value) {
      context.emit("selectOrganization", value);
    }

    return {
      filteredList,
      updatedList,
      isLoading,
      searchHintText,
      foundCount,
      showCount,
      prevSortCol,
      storedReverse,
      selectOrganization,
      sortTable
    }
  }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  color: black;
}
</style>